<template>
  <div class="video-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>视频管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="content-header">
        <el-button type="primary" size="mini" @click="addVideo">添加视频</el-button>
      </div>
      <div class="video-list" v-if="dataList.length > 0">
        <div class="video-item" v-for="item in dataList" :key="item.id">
          <div class="thumbnail" :style="'background-image: url(' + item.thumbnail + ')'"></div>
          <div class="title">{{item.title}}</div>
          <div class="item-options">
            <div class="edit" @click="editItem(item.id)">编辑</div>
            <div class="del" @click="delItem(item.id)">删除</div>
          </div>
          <div class="tag">
            <el-tag v-if="item.type === 1" size="mini">系统</el-tag>
            <el-tag type="warning" v-if="item.type === 2" size="mini">外部</el-tag>
          </div>
        </div>
      </div>
      <div class="no-list" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取会议列表
    async getDataList () {
      const { data: res } = await this.$http.get('/shell-video-list', { params: { mId: this.$route.params.mId } })
      if (res.status === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 添加
    addVideo () {
      this.$router.push({ path: '/admin/apps/shell/content/video/' + this.$route.params.mId + '/add', query: { meetingName: this.$route.query.itemName } })
    },
    // 编辑
    editItem (id) {
      this.$router.push({ path: '/admin/apps/shell/content/video/' + this.$route.params.mId + '/edit/' + id, query: { meetingName: this.$route.query.itemName } })
    },
    // 删除
    async delItem (id) {
      const { data: res } = await this.$http.delete('/shell-video/' + id)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.content-header{
  text-align: right;
}
.video-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px;
}
.video-list .video-item{
  width: 200px;
  height: auto;
  background-color: rgba(255,255,255,1);
  margin: 0 20px 20px 0;
  position: relative;
  padding-bottom: 24px;
}
.video-list .video-item:hover{
  box-shadow: rgb(0 0 0 / 15%) 0px 15px 25px, rgb(0 0 0 / 5%) 0px 5px 10px;
}
.video-list .video-item .thumbnail{
  width: 200px;
  height: 120px;
  background-color: #2d8cf0;
  background-size: cover;
  background-position: center;
}
.video-list .video-item .thumbnail img{
  width: 100%;
  height: 100%;
}
.video-list .video-item .title{
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.video-list .video-item .tag{
  position: absolute;
  top: 4px;
  right: 4px;
}
.video-list .video-item .item-options{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.video-list .video-item .item-options .edit{
  background-color: #409EFF;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 26px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}
.video-list .video-item .item-options .del{
  background-color: #F56C6C;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 26px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}
</style>
